<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export default {
  provide() {
    return {
      reload: this.reload,
      keyDownListener:null
    };
  },
  data() {
    return { isRouterAlive: true };
  },
  mounted(){
    const keyDownListener = this.handleKeyDown.bind(this);  
    document.addEventListener('keydown', keyDownListener);  
    this.keyDownListener = keyDownListener; // 保存引用以便稍后移
  },
  methods: {
    handleKeyDown(event) {  
      if (event.keyCode == 192) {  
        this.$store.dispatch('addKeyDownListener');   
      }  
    },  
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="scss">
.el-cascader-panel .el-radio {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 0px;
  /* margin-right: 10px; */
  /* right: 10px; */
}
.el-cascader-node__label {
  margin-left: 10px;
}
/* 这个样式针对IE有用，不考虑IE的可以不用管*/
.el-cascader-panel .el-cascader-node__postfix {
  top: 10px;
}
</style>
