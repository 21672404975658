<template>
  <div>
    <el-dialog v-model="dialogVisible" width="1100px" append-to-body :show-close="false">
      <template #title>
        <div class="dialog_box">
          <div class="con_title"><span></span> 选择产品</div>
          <div>
            <el-input icon="el-icon-search" v-model="queryParams.manufacturer" @keyup.enter.native="handleQuery"
              placeholder="请输入生产厂家" clearable style="width:240px;margin-right:10px" size="mini"></el-input>
            <el-input icon="el-icon-search" v-model="queryParams.productNo" @keyup.enter.native="handleQuery"
              placeholder="请输入产品编号" clearable style="width:240px;margin-right:10px" size="mini"></el-input>
            <el-input ref="seach" icon="el-icon-search" v-model="queryParams.product_no" placeholder="请输入产品编号/产品名称/助记码"
              clearable style="width:240px;margin-right:10px" size="mini" @blur="seachBlur"
              @focus="seachFocus"></el-input>
            <el-button size="mini" type="primary" @click="handleQuery()">搜索</el-button>
          </div>
        </div>
      </template>
      <el-table :data="list" v-loading="loading" v-if="!is_sale" style="width: 100%" ref="tableDataRefs"
        :class="is_single ? 'is_single' : ''" @selection-change="handleSelectionChange" @row-click="handleRowClick">

        <!-- <el-table-column prop="id" label="行号" /> -->
        <el-table-column type="index" width="50">
        </el-table-column>
        <el-table-column prop="product_no" label="产品编号" width="160" />
        <el-table-column prop="product_name" label="品名" />
        <!-- <el-table-column prop="mnemonic_code" label="助记码" /> -->
        <el-table-column prop="model" label="型号规格" width="120" />
        <el-table-column prop="manufacturer" label="生产厂家" width="260" />
        <el-table-column prop="pack_unit_name" label="包装单位" width="100" />
        <el-table-column type="selection" width="55" :selectable="selectable_func" />
      </el-table>


      <el-table :data="list" v-loading="loading" v-if="is_sale" style="width: 100%" ref="tableDataRefs"
        :class="is_single ? 'is_single' : ''" @selection-change="handleSelectionChange" @row-click="handleRowClick">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="product_no" label="产品编号" width="130" />
        <el-table-column prop="product_name" label="品名" />
        <el-table-column prop="manufacturer" label="生产厂家" width="260" />

        <el-table-column prop="pack_unit_name" label="包装单位" width="90" />
        <!-- <el-table-column prop="model" label="型号规格"  width="120" /> -->
        <el-table-column prop="stock" label="库存数量" width="90" />
        <el-table-column type="selection" width="55" :selectable="selectable_func" />
      </el-table>
      <pagination v-show="total > 0" :total="total" v-model:page="queryParams.page" v-model:limit="queryParams.size"
        @pagination="getList" />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" :disabled="multiple" @click="confirm_sele">确定</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'selectProducts',
  props: {

  },
  data() {
    return {
      // 列表模板
      loading: false,
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        product_no: '',
        productNo: '',
        manufacturer: ''
      },
      total: 0,
      list: [],
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      dialogVisible: false,
      disabled_ids: [],  // 上级页面已选择的，这里不能再选
      is_single: false,   //是否只能选一个产品
      is_sale: false,
      lastEnterTime: 0, // 上一次按下回车的时间  
      enterInterval: 500, // 设置两次回车之间的时间间隔，单位为毫秒  
      seachFlag: false
    }
  },
  mounted() {
    document.addEventListener('keydown', (event) => {
      if(!this.dialogVisible) return
      if (event.keyCode == 13) {
        this.handleEnter()
      }
      if (!this.seachFlag) {
        let a = Number(event.key)
        if (0 <= a && a <= 9) {
          a == 0 ? a = 10 : a
          if (this.list.length < 0) {
            return
          }
          if (this.ids.includes(this.list[a - 1].id)) {
            this.$refs.tableDataRefs.toggleRowSelection(this.list[a - 1], false)
          } else {
            let id = this.list[a - 1].id
            if(this.disabled_ids.indexOf(id) == -1){
              this.$refs.tableDataRefs.toggleRowSelection(this.list[a - 1], true)
            }
          }
        }
      }
    })
  },
  methods: {
    handleRowClick(row, column, event) {
      if (this.ids.includes(row.id)) {
        this.$refs.tableDataRefs.toggleRowSelection(row, false)
      } else {
        this.$refs.tableDataRefs.toggleRowSelection(row, true)
      }
    },
    seachFocus(e) {
      this.seachFlag = true;
    },
    seachBlur(e) {
      this.seachFlag = false;
    },
    init(ids, is_single, is_sale) {
      this.dialogVisible = true;
      this.queryParams = {
        page: 1,
        size: 10,
        product_no: '',
        productNo: '',
        manufacturer: ''
      }
      this.disabled_ids = ids;
      this.is_single = is_single == 1 ? true : false;
      this.is_sale = is_sale == 1 ? true : false;
      this.getList()
      setTimeout(() => {
        this.$refs["seach"].focus();
      }, 500);
    },
    handleEnter() {
      const now = new Date().getTime();
      if (now - this.lastEnterTime < this.enterInterval) {
        // 如果两次回车的时间间隔小于设定的间隔，则执行连续按下的逻辑  
        // console.log('执行----------->');
        this.onDoubleEnter(); // 执行连续按回车的回调函数  
      } else {
        // 重置上一次回车的时间 
        this.lastEnterTime = now;
        // console.log('不执行----------->');
        if (this.seachFlag) {
          this.handleQuery()
        } else {
          this.confirm_sele()
        }
      }
    },
    onDoubleEnter() {
      if (this.list.length == 1) {
        this.ids = [this.list[0].id]
        this.$refs.tableDataRefs.toggleRowSelection(this.list[0], true);
        this.confirm_sele()
      }
    },
    confirm_sele() {
      let arr = [];
      for (let i = 0; i < this.list.length; i++) {
        if (this.ids.indexOf(this.list[i].id) != -1) {
          arr.push(this.list[i])
        }
      }
      this.$emit('after_sele', arr);
      this.dialogVisible = false;
    },
    // 已选的id禁选
    selectable_func(row, index) {
      if (this.disabled_ids.indexOf(row.id) != -1) {
        return false
      }
      return true
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      if (this.is_single) {
        if (selection.length <= 1) {
          this.ids = selection.map(item => item.id);
        }
        if (selection.length > 1) {
          this.ids = [selection[1].id]
          let del_row = selection.shift()
          this.$refs.tableDataRefs.toggleRowSelection(del_row, false)
        }
        this.single = this.ids.length != 1;
        this.multiple = !this.ids.length;
      } else {
        this.ids = selection.map(item => item.id);
        this.single = selection.length != 1;
        this.multiple = !selection.length;
      }
    },
    /** 查询列表 */
    getList() {
      this.loading = true;
      let url = '/backend/product.Product/index';
      if (this.is_sale) {
        url = '/backend/product.Product/sale_index';
      }
      this.$httpGet(url, this.queryParams).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          this.list = res.data.data;
          // console.log(this.list, '111111111');
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    }
  }
}
</script>

<style scoped>
.dialog_box {
  display: flex;
  justify-content: space-between;
}
</style>
